import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import store from "@/store/index";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/game",
    name: "game",
    component: () => import("@/views/GameView.vue"),
  },
  {
    path: "/togame/:user",
    name: "togame",
    component: () => import("@/views/ToGameView.vue"),
  },
  {
    path: "/notHavePermission",
    name: "NotHavePermission",
    components: {
      default: () => import("@/views/NotHaveAccesView.vue"),
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
// This callback runs before every route change, including on page load.
router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): Promise<void> => {
    if (to.name && to.name === "game" && store.state.player) {
      return next();
    }
    if ((to.name && to.name === "togame") || to.name === "NotHavePermission") {
      return next();
    }

    return next({ name: "NotHavePermission" });
  }
);

export default router;
