import { MutationType } from "@/store/mutations/type";
import { IStoreType } from "@/interfaces/IStoreType";
import { IResult } from "@/interfaces/IResult";

export default {
  [MutationType.SET_USER]: (state: IStoreType, data: string): void => {
    state.user = data;
  },
  [MutationType.SET_TOKEN]: (state: IStoreType, token: string): void => {
    state.token = token;
  },
  [MutationType.SET_FULLSCREEN]: (state: IStoreType, val: boolean): void => {
    state.fullScreen = val;
  },
  [MutationType.SET_LAS_RESULT]: (state: IStoreType, val: IResult[]): void => {
    state.lastResult = val;
  },
  [MutationType.SET_SESSION_ID]: (state: IStoreType, val: string): void => {
    state.sessionID = val;
  },
};
