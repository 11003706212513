import { MutationType } from "@/store/mutations/type";
import { IStoreType } from "@/interfaces/IStoreType";
import { GettersType } from "@/store/getters/type";
import store from "@/store/index";
import { IChip } from "@/interfaces/IChip";
export default {
  [MutationType.OPEN_CHIP_LIST]: (state: IStoreType): void => {
    state.chipListOpen = !state.chipListOpen;
  },
  [MutationType.CLOSE_CHIP_LIST]: (state: IStoreType): void => {
    if (state.chipListOpen) {
      state.chipListOpen = false;
    }
  },
  [MutationType.SET_ACTIVE_CHIP]: (
    state: IStoreType,
    position: number
  ): void => {
    if (
      state.player &&
      state.player.lastBalance &&
      state.chips[position].value <= state.player.lastBalance
    ) {
      store.getters[GettersType.CHIP_ACTIVE].active = false;
      state.chips[position].active = true;
    }
  },
  [MutationType.SET_CHIPS]: (state: IStoreType, chip: IChip[]): void => {
    state.chips = chip;
  },
};
