export enum MutationType {
  SET_FULLSCREEN = "setFullScreen",
  SET_USER = "setUser",
  SET_TOKEN = "setToken",
  SET_LAS_RESULT = "setLastResul",
  SET_STREAMING_LOADING = "setStreamingLoading",
  SET_STREAMING = "setStreaming",
  SET_STREAMING_ERROR = "setStreamingError",
  SET_STREAMING_RELOAD = "setStreamingReload",
  SET_STREAMING_PAUSE = "setStreamingPause",
  SET_GAME = "setGame",
  SET_GAMES = "setGames",
  SET_VOLUME = "setVolume",
  SET_PLAYER = "setPlayer",
  SET_CURRENCY = "setCurrency",
  OPEN_CHIP_LIST = "openChipList",
  CLOSE_CHIP_LIST = "closeChipList",
  SET_ACTIVE_CHIP = "setActiveChip",
  SET_CHIPS = "setChips",
  SET_BET = "setBet",
  SET_ROUND = "setRound",
  SET_CYCLE = "setCycle",
  SET_CYCLE_TEXT = "setCycleText",
  RESET_SIMPLE_BET = "resetSimpleBet",
  SET_BET_TIME = "setBetTime",
  REST_BALANCE = "resBalanceToPlayer",
  ADD_BALANCE = "addBalanceToPlayer",
  SET_MULTIPLIERS = "setMultiplier",
  SET_LANGUAGE = "setLanguage",
  SET_LAST_BET = "setLastBet",
  SET_AUTOMATIC_GAME = "setAutomaticGame",
  OPEN_AUTOMATIC_GAME = "openAutomaticGame",
  ADD_AUTOMATIC_BET = "addAutoGame",
  SET_AUTOMATIC_ROUND = "setAutomaticRound",
  SET_AUTOMATIC_VALUE = "setAutomaticValue",
  RESET_AUTOMATIC_BET = "resetAutomaticBEt",
  TOGGLE_SETTING = "toggleSetting",
  SET_OPERATOR = "setOperator",
  SET_BALANCE = "setbalance",
  SET_WIN = "setWin",
  SET_EARNINGS = "setEarning",
  SET_HISTORY_BET = "setHistoryBet",
  SET_SHOW_HISTORY_BET = "setHistoryBet",
  SET_LOBBY_HOST = "setLobbyHost",
  SET_LOBBY = "setLobby",
  SET_INIT_BOND = 'setInitBond',
  SET_CYCLONE_BOND = 'setCyclonBond',
  SET_RAY_BOND = 'setRayBond',
  SET_MUTE = "setMute",
  SET_BACKGROUND_IMAGE = "setBackgroundImage",
  SET_SESSION_ID = "setSessionId",
  SET_VIDEO_COLOR = "setVideoColor",
  SET_MAX_BET_FIGURE = "setMaxBetFigure",
  SET_COUNT_BET_FIGURE = "setCountBetFigure",
  SET_BUTTON_LOBBY = "setButtonLobby",
  SET_BUTTON_SUPPORT = "setButtonSupport",
  SET_CONTACTUS = "setContactus",

}
