<template>
  <router-view />
</template>

<style lang="scss">
@import "@/styles/base.scss";

:root {
  --chip-size: 2.75rem;
  --btn-top: 2.75rem;
  --time-line-height: 2.2rem;
  
  @include r(mobile) {
    --time-line-height: 1rem;
    --chip-size: 2rem;
  }
}
</style>
