import { GettersType } from "@/store/getters/type";
import { IStoreType } from "@/interfaces/IStoreType";
import { IGame } from "@/interfaces/IGame";
import { IBet } from "@/interfaces/IBet";
import { IRound } from "@/interfaces/IRound";
import { ISimpleBet } from "@/interfaces/ISimpleBet";
import { IMultiplier } from "@/interfaces/IMultiplier";

export default {
  [GettersType.GET_GAME]: (state: IStoreType): IGame | null => state.game,
  [GettersType.GET_BET]: (state: IStoreType): IBet | null => state.bet,
  [GettersType.GET_ROUND]: (state: IStoreType): IRound | null => state.round,
  [GettersType.GET_BET_TIME]: (state: IStoreType): number | string =>
    state.round && state.round.betTime ? Math.floor(state.round.betTime) : "",
  [GettersType.GET_BET_TIME_DURATION]: (state: IStoreType): number | string =>
    state.round && state.round.duration ? Math.floor(state.round.duration) : "",
  [GettersType.GET_CYCLE]: (state: IStoreType): number => state.cycle,
  [GettersType.GET_TOTAL_AMOUNT]: (state: IStoreType): number => {
    if (!state.bet) return 0;
    return state.bet.bet.reduce(
      (total: number, element: ISimpleBet): number => {
        return total + element.amount;
      },
      0
    );
  },
 [GettersType.GET_MULTIPLIER]: (state: IStoreType): IMultiplier[] =>
    state.multipliers,
  [GettersType.GET_LANGUAGE]: (state: IStoreType): string => state.lang,
  [GettersType.GET_LAST_BET]: (state: IStoreType): ISimpleBet[] =>
    state.lastBet,
  [GettersType.GET_AUTOMATIC_GAME]: (state: IStoreType): boolean =>
    state.automaticGame,
  [GettersType.GET_AUTOMATIC_ROUND]: (state: IStoreType): number =>
    state.automaticRounds,
  [GettersType.GET_OPEN_AUTOMATIC_GAME]: (state: IStoreType): boolean =>
    state.automaticGameOpen,
  [GettersType.IS_WIN]: (state: IStoreType): boolean => state.isWin,
  [GettersType.GET_EARNING]: (state: IStoreType): number | null => state.earning,
  [GettersType.SHOW_HISTORY_BET]: (state: IStoreType): boolean => state.showHistoryBet,
  [GettersType.GET_INIT_BOND]:(state: IStoreType): boolean => state.megaBond.init,
  [GettersType.GET_CYCLONE_BOND]:(state: IStoreType): boolean => state.megaBond.cyclone,
  [GettersType.GET_RAY_BOND]:(state: IStoreType): boolean => state.megaBond.ray,
  [GettersType.GET_BACKGROUND_IMAGE]:(state: IStoreType): string | null => state.imgBackground,
  [GettersType.GET_MAX_BET_FIGURE]:(state: IStoreType): number | null => state.maxBetFigures,
  [GettersType.GET_BUTTON_LOBBY]:(state: IStoreType): boolean => state.button_lobby,
  [GettersType.GET_BUTTON_SUPPORT]:(state: IStoreType): boolean => state.button_support,
  [GettersType.GET_COUNT_BET_FIGURE]:(state: IStoreType): number  => {
    if (!state.bet) return 0;
    return state.bet.bet.length;
  },
};
