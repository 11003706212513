import { ActionType } from "@/store/actions/type";
import { Commit } from "vuex";
import { MutationType } from "@/store/mutations/type";


export default {
  [ActionType.SET_CHIP]: (
    { commit }: { commit: Commit },
    position: number
  ): void => {
    commit(MutationType.SET_ACTIVE_CHIP, position);
  },
  
};
