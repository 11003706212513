export enum ActionType {
  LOGIN = "login",
  REFRESH_LOGIN = "refreshLogin",
  LOGOUT = "logout",
  SEARCH_STATUS_TICKETS = "searchStatusTickets",
  SEARCH_PROJECTS = "searchProjects",
  LIST_TICKET_TYPES = "listTicketTypes",
  LIST_DEPARTMENT = "lisDepartment",
  SEARCH_TICKETS = "searchTickets",
  SEARCH_MY_TICKETS = "searchMyTickets",
  FIND_DATA_ONE_PROJECT = "findDataOneProject",
  LIST_PRIORITIES = "listPriorities",
  CREATE_TICKET = "createTicket",
  CREATE_MEDIA_OBJECT = "createMedia",
  REMOVE_MEDIA_OBJECT = "removeMedia",
  FIND_MEDIA_OBJECT = "findMedia",
  FIND_USERS = "findUsers",
  GET_DATA_ONE_TICKET = "getDataOneTicket",
  ADD_COMMENTARY = "addCommentary",
  READ_COMMENTARY = "readCommentary",
  UPDATE_TICKETS = "updatesTickets",
  FORGET_PASSWORD = "forgetPassword",
  CHANGE_PASSWORD = "changePassword",
  INIT = "init",
  CHECK_GAME = "checkGame",
  SET_CHIP = "setChip",
  ADD_BET = "addBet",
  UPDATE_RESULT = "updateResult",
  SEACH_HISTORY_PLAYER = "searchHistoryPlayer",
  GO_TO_LOBBY = "goToLobby",
  ADD_AUTOMATIC_BET = "addAutomaticBetAction",
}
