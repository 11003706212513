import { IResult } from "@/interfaces/IResult";

export class Result implements IResult {
  jackpot: boolean;
  multiplier: number;
  result: number;
  constructor(result: number, jackpot: boolean, multiplier: number) {
    this.result = result;
    this.jackpot = jackpot;
    this.multiplier = multiplier;
  }
}
