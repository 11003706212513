import { ErrorType } from "@/interfaces/ErrorType";
export default class MainError {
  code: string | unknown;
  message: string;
  constructor(value: ErrorType) {
    this.code = value.code;
    this.message = value.message;
  }

  public  getMessage():string {
    return this.message;
  }
  public  getCode():string | unknown {
    return this.code;
  }
}
