import axios from "axios";
export default (baseUrl?:string | null | undefined) => {
  return axios.create({
    baseURL: !baseUrl ? process.env.VUE_APP_API_URL: baseUrl,
    withCredentials: false,
    headers: {
      Accept: "application/ld+json",
      "Content-Type": "application/ld+json",
      "Access-Control-Allow-Origin": '*',
    },
  });
};
