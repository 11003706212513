import { IChip } from "@/interfaces/IChip";
import { IColorChip } from "@/interfaces/IColorChip";
export class Chip implements IChip {
  color: IColorChip = { primary: "", secondary: "" };
  value = 0;
  id = "";
  currency = "";
  active = false;
  constructor(
    id: string,
    value: number,
    color: IColorChip,
    currency: string,
    active: boolean
  ) {
    this.id = id;
    this.currency = currency;
    this.color = color;
    this.value = value;
    this.active = active;
  }
}
