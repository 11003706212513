import { GettersType } from "@/store/getters/type";
import { UserType } from "@/interfaces/UserType";
import MainError from "@/exception/MainError";
import { UserRole } from "@/interfaces/UserRole";
import { IStoreType } from "@/interfaces/IStoreType";
import { IResult } from "@/interfaces/IResult";

export default {
  [GettersType.GET_CURRENT_USER]: (state: IStoreType): string =>
    state.user,
  [GettersType.GET_TOKEN]: (state: IStoreType): string =>
    state.token,
  [GettersType.IS_ADMIN]: (): boolean => {
    try {
      const data: string | null = localStorage.getItem("etsaUser");
      if (!data) return false;
      const user: UserType = JSON.parse(data);
      return user.roles?.includes(UserRole.ADMIN);
    } catch (e) {
      throw new MainError({ message: "Ususaio sin autorizo" });
    }
  },
  [GettersType.GET_FULLSCREEN]: (state: IStoreType): boolean =>
    state.fullScreen,
  [GettersType.GET_LAST_RESULT]: (
    state: IStoreType
  ): IResult[] | undefined | null => state.lastResult,
  [GettersType.GET_SESSION_ID]: (
    state: IStoreType
  ): string  | null => state.sessionID,
};
