import pt from "@/locales/pt.json";
import en from "@/locales/en.json";
import es from "@/locales/es.json";
import { createI18n } from "vue-i18n";

export default createI18n({
  locale: navigator.language,
  fallbackLocale: 'en',
  formatFallbackMessages: true,
  messages: { pt, en, es },
  legacy: false,
});
